
<template>
    <v-app :style="colorVapp">
      <onebox_admin_toolbar
        :quicksearch="true"
        @loaddata="loaddata()"
        @loadfile="loadfolder()"
        @callstorage="loadstorage()"
        @closeDrag="removeEvent()"
        @openDrag="addEvent()"
      ></onebox_admin_toolbar>
      <v-content>
          <v-card class="elevation-0" :color="color.BG">
          <v-divider></v-divider>
          <v-overlay :value="processloader" absolute :color="color.BG" dark>
            <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>
            <span :style="colorProgress">&nbsp; loading</span>
          </v-overlay>
          
          <!-- hearder -->
          <v-list nav :color="color.BG">
            <v-list-item class="text-left">
              <v-list-item-avatar class="text-center">
                <v-avatar :color="color.theme" size="35">
                  <v-icon dark size="20">mdi-note-text</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="pa-1">
                  <span
                    :style="headerPage" 
                  >&nbsp;{{$t('admintoolbar.template_feature')}}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          
  
          <v-card class="elevation-0" :color="color.BG" id="thiscontainersetemailadmin">
              <v-card-text class="pa-0">
                  <v-container fluid>
                      <v-layout row wrap justify-center fill-height>
                          <v-flex lg12 class="ma-4">
                          <v-layout row wrap class="pl-3 pr-3" v-if="resolutionScreen >= 500 || ['md', 'lg', 'xl'].includes($vuetify.breakpoint.name)">
                          <v-flex md3>
                            <v-text-field
                            append-icon="mdi-magnify"
                            dense outlined :label="$t('smartinput.search_template_name')"
                            v-model.trim="search_template_name"
                            clearable
                            @click:clear="fn_clear_search()"
                            />
                          </v-flex>
                          <v-spacer></v-spacer>
                          <v-flex md3 style="display: flex; justify-content: end;" >
                            <v-btn :dark="color.darkTheme" :color="color.theme" @click="fn_active_template('SC', undefined)"
                              ><v-icon>add</v-icon>&nbsp;{{ $t('smartinput.create_template') }}</v-btn>
                          </v-flex>
                          </v-layout>
                          <v-layout v-else class="px-4">
                            <v-flex md3 xs8>
                            <v-text-field
                            append-icon="mdi-magnify"
                            dense outlined :label="$t('smartinput.search_template_name')"
                            v-model.trim="search_template_name"
                            clearable
                            @click:clear="fn_clear_search()"
                            />
                          </v-flex>
                          <v-spacer></v-spacer>
                            <v-flex xs1 style="display: flex; justify-content: end;">
                            <v-btn small fab dark :color="color.theme" @click="fn_active_template('SC', undefined)"
                              ><v-icon>mdi-note-plus</v-icon></v-btn>
                          </v-flex>
                          </v-layout>
                          <div class="mt-3" v-if="isloading_page_skeleton" >
                            <v-skeleton-loader
                                v-if="resolutionScreen >= 500"
                                class="mx-auto"
                                type="table"
                            ></v-skeleton-loader>
                            <v-skeleton-loader
                                v-else
                                class="mx-auto"
                                type="list-item-two-line"
                            ></v-skeleton-loader>
                          </div>
                          <div v-else>
                          <div class="mt-3" v-if="resolutionScreen >= 500">
                              <v-data-table
                              :headers="header"
                              :items="show_template_data"
                              class="elevation-0"
                              :page.sync="page"
                              :items-per-page="size"
                              :hide-default-footer="true"
                              :no-data-text="$t('smartinput.no_data_templates')"
                              @page-count="pageCount = $event">
                                  <template  v-slot:[`header.type`]="{ header }">
                                  <span :style="headerTable">{{ header.text }}</span>
                                  </template>
                                  <!-- <template  v-slot:[`header.template_key`]="{ header }">
                                  <span :style="headerTable">{{ $t(header.text) }}</span>
                                  </template> -->
                                  <template v-slot:[`header.create_name_template`]="{ header }">
                                  <span :style="headerTable">{{ $t(header.text) }}</span>
                                  </template>
                                  <template  v-slot:[`header.template_creator`]="{ header }">
                                  <span class="text-right" :style="headerTable">{{ $t(header.text) }}</span>
                                  </template>
                                  <template  v-slot:[`header.template_date_created`]="{ header }">
                                  <span class="text-right" :style="headerTable">{{ $t(header.text) }}</span>
                                  </template>
                                  <template  v-slot:[`header.Manage`]="{ header }">
                                  <span class="text-right" :style="headerTable">{{ $t(header.text) }}</span>
                                  </template>
                                  <template v-slot:item="props">
                                  <tr>
                                      <td width="7%" class="text-center">
                                          <v-icon>mdi-note-text</v-icon>
                                      </td>
                                      <td width="40%" class="font-weight-bold" style="font-size: 15px;">
                                          {{props.item.create_name_template | subStr}}
                                      </td>
                                      <td width="18%" class="font-weight-bold" style="font-size: 15px;">
                                          {{props.item.template_creator}}
                                      </td>
                                      <td width="30%" class="font-weight-bold" style="font-size: 15px;">
                                          {{fn_formatdatetime(props.item.template_date_created)}}
                                      </td>
                                      <td width="10%">
                                          <v-layout>
                                            <v-tooltip left>
                                              <template v-slot:activator="{attrs }">
                                                <v-btn v-bind="attrs" fab text small @click="fn_active_template('ED', props.item)">
                                                <v-icon class="mr-2">
                                                mdi-pencil
                                                </v-icon>
                                                </v-btn>
                                              </template>
                                              <span>แก้ไข</span>
                                            </v-tooltip>
                                          <!-- <v-btn fab text small @click="fn_active_template('V', props.item)">
                                              <v-icon>
                                                mdi-eye-outline
                                          </v-icon>
                                          </v-btn> -->
                                          </v-layout>
                                      </td>
                                  </tr>
                                  </template>
                              </v-data-table>
                          </div>
                          <div class="mt-3" v-else-if="['sm', 'xs'].includes($vuetify.breakpoint.name)">
                          <v-list two-line class="mb-6 pb-6 elevation-0 px-4" v-if="show_template_data.length !== 0" :color="color.BG">
                              <v-list-item v-for="item in show_template_data.slice(index_from,index_to)" :key="item.template_key" :style="`cursor: pointer; background-color: ${$vuetify.theme.dark ? '#212121' : 'white'}`">
                              <v-list-item-action>
                                <v-icon>mdi-note-text</v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                  <v-list-item-title> 
                                      {{ item.create_name_template | subStr }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle style="line-height: inherit;">
                                      {{ item.template_creator }}
                                  </v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action >
                                  <v-btn class="elevation-0" fab small @click="isMenu_mobile = true, selected_template_field = item " ><v-icon>more_vert</v-icon></v-btn>
                                </v-list-item-action>
                              </v-list-item>
                          </v-list>
                          <v-list v-else :color="color.BG">
                            <v-list-item-content>
                             <v-layout justify-center align-center >
                              <span class="font-weight-bold" style="font-size: 15px;">{{$t('smartinput.no_data_templates')}}</span>
                             </v-layout>
                            </v-list-item-content>
                          </v-list>
                          </div>
                          <v-layout row wrap justify-center class="mt-5">
                              <v-flex xs11 lg11 v-if="pageCount > 1">
                                <v-pagination
                                  :total-visible="10"
                                  v-model="page"
                                  :length="pageCount || 0"
                                  :color="color.theme"
                                  @input="fn_clickpagination($event)"
                                ></v-pagination>
                              </v-flex>
                              </v-layout>
                          </div>
                          </v-flex>
                      </v-layout>
                  </v-container>
              </v-card-text>
          </v-card>
          </v-card>
          <dialog_template 
            :show_dialog_template="isOpen_template"
            :type_show_template="type_show"
            :template_field_infor="selected_template_field"
            :backup_mockup="test_mockup_template"
            @close_dialog="fn_close_dialog"
          ></dialog_template>
          <v-bottom-sheet v-model="isMenu_mobile">
            <v-list dense tile>
              <v-list-item
                  v-for="(item, i) in menu_mobile"
                  :key="i"
                  @click="fn_active_template(item.type, selected_template_field)"
                >
                  <v-list-item-icon>
                    <v-icon medium> {{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(item.text)}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
            </v-list>
          </v-bottom-sheet>
      </v-content>
    </v-app>
  </template>
  
  <script>
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import { mapState, mapGetters } from "vuex";
  import axios from "axios";
  import gbfGenerate from "@/globalFunctions/generateAuthorize";
  import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
  import onebox_admin_toolbar from "../components/layout/layout-admin-toolbar";
  const dialog_template = () => import("../components/optional/dialog-template");
  export default {
      components:{
       onebox_admin_toolbar,
       dialog_template
      },
      watch: {
        windowWidth: function (newval, oldval) {
          console.log("HELLO ", newval)
          return newval
        },
        search_template_name: function (newVal, oldVal) {
          if(newVal === "" || newVal === null){
            this.show_template_data = this.template_data
            this.size = 10
            return
          } else {
            this.page = 1
            // this.show_template_data = this.template_data.filter((item) => {
            //   let findWord = newVal.toLowerCase()  
            //   if(item.create_name_template.toLowerCase().includes(findWord)){
            //     console.log("ITEM FOUND ", item)
            //     return item
            //   }
            // })
            this.show_template_data = this.fn_searching_by_name(newVal)
            this.size = this.show_template_data.length
            console.log("page ", this.page);
            console.log("template ", this.show_template_data);
          }
          
        }
      },
      filters: {
        subStr: function(string) {
          if (string == null || string == "" || string == undefined) {
              return string;
          } else {
              if (string.length > 50) return string.substring(0, 50) + "...";
              else return string;
          }
        },
      },
      data :function(){
      return{
        isloading_page_skeleton: false,
        windowWidth: window.innerWidth,
        isOpen_template: false,
        loaddataprogress:false,
        title_en:"",
        title_th:"",
        email:"",
        first_name_th:"",
        first_name_eng:"",
        mobile:"",
        size: 10,
        offset: 0,
        page: 1,
        header: [
          {
            text: "#",
            align: "center",
            value: "type",
            width: "7%",
            sortable: false,
          },
          // {
          //   text: "Template Key",
          //   align: "",
          //   value: "template_key",
          //   width: "10%",
          //   sortable: false,
          // },
          {
            text: "smartinput.header_table.template_name",
            align: "",
            value: "create_name_template",
            width: "40%",
            sortable: false,
          },
          {
            text: "smartinput.header_table.template_owner",
            align: "",
            value: "template_creator",
            width: "13%",
            sortable: false,
          },
          {
            text: "smartinput.header_table.template_date",
            align: "",
            value: "template_date_created",
            width: "30%",
            sortable: false,
          },
          {
            text: "smartinput.header_table.template_manage",
            align: "ceter",
            value: "Manage",
            width: "10%",
            sortable: false,
          },
        ],
        mockup_template: [
          {
              template_key: "TM001",
              create_name_template: "Invoice Taxbox",
              template_creator: "Bruno Fernan",
              template_date_created: "07/02/2024",
              template_fields: [
                {
                  template_field_id: 1,
                  template_field_key: "TCL111",
                  template_field_name: "Etax Easy E-Receipt",
                  template_field_type: {items_id: 1, items_text: "Text", items_value: "text"},
                  template_field_dropdown: [],
                  template_field_required: false
                },
                {
                  template_field_id: 2,
                  template_field_key: "TCL222",
                  template_field_name: "Onebox",
                  template_field_type: {items_id: 3, items_text: "Dropwdown", items_value: "select"},
                  template_field_dropdown: [
                    {dropdown_key: 1, dropdown_value: "CAT"},
                    {dropdown_key: 2, dropdown_value: "DOG"},
                    {dropdown_key: 3, dropdown_value: "COW"}
                  ],
                  template_field_required: true
                },
                {
                  template_field_id: 3,
                  template_field_key: "TCL333",
                  template_field_name: "One centric X Onebox",
                  template_field_type: {items_id: 2, items_text: "Date", items_value: "date"},
                  template_field_dropdown: [],
                  template_field_required: true
                }
              ]
          },
          {
              template_key: "TM002",
              create_name_template: "Receipt Taxbox",
              template_creator: "Rio Ferdinand",
              template_date_created: "12/02/2024",
              template_fields: [
                {
                  template_field_id: 1,
                  template_field_key: "RD001",
                  template_field_name: "7 All",
                  template_field_type: {items_id: 1, items_text: "Text", items_value: "text"},
                  template_field_dropdown: [],
                  template_field_required: false
                }
              ]
          }
        ],
        test_mockup_template: [
          {
              template_key: "TM001",
              create_name_template: "Invoice Taxbox",
              template_creator: "Bruno Fernan",
              template_date_created: "07/02/2024",
              template_fields: [
                {
                  template_field_id: 1,
                  template_field_key: "TCL111",
                  template_field_name: "Etax Easy E-Receipt",
                  template_field_type: {items_id: 1, items_text: "Text", items_value: "text"},
                  template_field_dropdown: [],
                  template_field_required: false
                },
                {
                  template_field_id: 2,
                  template_field_key: "TCL222",
                  template_field_name: "Onebox",
                  template_field_type: {items_id: 3, items_text: "Dropwdown", items_value: "select"},
                  template_field_dropdown: [
                    {dropdown_key: "DD1", dropdown_value: "CAT"},
                    {dropdown_key: "DD2", dropdown_value: "DOG"},
                    {dropdown_key: "DD3", dropdown_value: "COW"}
                  ],
                  template_field_required: true
                },
                {
                  template_field_id: 3,
                  template_field_key: "TCL333",
                  template_field_name: "One centric X Onebox",
                  template_field_type: {items_id: 2, items_text: "Date", items_value: "date"},
                  template_field_dropdown: [],
                  template_field_required: true
                }
              ]
          },
          {
              template_key: "TM002",
              create_name_template: "Receipt Taxbox",
              template_creator: "Rio Ferdinand",
              template_date_created: "12/02/2024",
              template_fields: [
                {
                  template_field_id: 1,
                  template_field_key: "RD001",
                  template_field_name: "7 All",
                  template_field_type: {items_id: 1, items_text: "Text", items_value: "text"},
                  template_field_dropdown: [],
                  template_field_required: false
                }
              ]
          }
        ],
        isMenu_mobile: false,
        menu_mobile: [
          // {
          //   text: "smartinput.template_detail",
          //   icon: "mdi-eye",
          //   type: "V",
          //   event: "fn"
          // },
          {
            text: "smartinput.template_edit",
            icon: "mdi-pencil",
            type: "ED",
            event: "fn"
          }
        ],
        template_data: [],
        show_template_data: [],
        test_mockup: "",
        backup_mockup_template: [],
        selected_template_field: {},
        backup_selected_template_field: {},
        type_show: "",
        backup_template_form_field: [],
        search_template_name: ""
      }
      },
      computed: {
      ...mapState(["username", "authorize", "account_active", "service","color"]),
      ...mapState({ processloader: "loading" }),
      ...mapGetters([
        "dataUsername",
        "dataAuthorize",
        "dataAccountActive",
        "dataAccesstoken",
        "dataBusinessProfile",
        "dataCitizenProfile",
        "dataDepartmentAccessId",
        "dataAccountId",
        "dataStorageUsage",
        "dataStorageMax"
      ]),
      resolutionScreen() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 220;
          case "sm":
            return 400;
          case "md":
            return 500;
          case "lg":
            return 600;
          case "xl":
            return 800;
        }
      },
      pageCount: {
        get() {
          let l = this.show_template_data.length;
          let s = this.size;
          return Math.ceil(l / s);
        },
        set(newName) {
          return newName;
        }
      },
      index_from () {
        return this.page === 1 ? 0 : this.size * (this.page - 1)
      },
      index_to() {
        return this.size * this.page
      },
      paginatedData() {
        const start = (this.page - 1) * this.size;
        const end = start + this.size;
        return this.rootfile.slice(start, start + this.size);
      },
      showdatafilter() {
        // console.log(search);
        return this.rootfile.filter(item => {
          return (
            item.file_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      },headerTable(){
        return 'color:'+this.color.theme+';'+'font-weight: 600; font-size:15px;'
      },headerPage(){
        // console.log(this.color.theme);
        
        return 'color:'+this.color.theme+';'+'font-size: 18px; font-weight: 600;'
      },colorVapp(){
        return 'background:'+this.color.BG+';'
      },colorSort(){
        return 'color:'+this.color.alertText+';'
      },colorProgress(){
        return 'color:'+this.color.theme+';'
      },   
      loaddatacom(){
      this.title_en = this.dataAccountActive.business_info.account_title_eng;
      this.title_th = this.dataAccountActive.business_info.account_title_th;
      this.email = this.dataAccountActive.business_info.email;
      this.first_name_th = this.dataAccountActive.business_info.first_name_th;
      this.first_name_eng = this.dataAccountActive.business_info.first_name_eng;
      this.mobile = this.dataAccountActive.business_info.mobile;
      }
      },
      created(){
      this.loaddata();
      this.title_en = this.dataAccountActive.business_info.account_title_eng;
      this.title_th = this.dataAccountActive.business_info.account_title_th;
      this.email = this.dataAccountActive.business_info.email;
      this.first_name_th = this.dataAccountActive.business_info.first_name_th;
      this.first_name_eng = this.dataAccountActive.business_info.first_name_eng;
      this.mobile = this.dataAccountActive.business_info.mobile;
      },
      methods:{
          loaddata(){
              console.log("dd",this.dataAccountActive.business_info.account_title_eng);
              this.title_en = this.dataAccountActive.business_info.account_title_eng;
              this.title_th = this.dataAccountActive.business_info.account_title_th;
              this.email = this.dataAccountActive.business_info.email;
              this.first_name_th = this.dataAccountActive.business_info.first_name_th;
              this.first_name_eng = this.dataAccountActive.business_info.first_name_eng;
              this.mobile = this.dataAccountActive.business_info.mobile;
          },
          fn_active_template(type, items) {
            console.log("Items field ", items)
            if(type === 'ED' || type === 'V'){
              this.selected_template_field = items
              //this.test_mockup = items.create_name_template
            }
            this.isMenu_mobile = false
            this.type_show = type
            this.isOpen_template = true
          },
          async fn_get_template () {
            this.isloading_page_skeleton = true
            let auth = await gbfGenerate.generateToken();
            const payload = {
              business_id: this.dataAccountActive.business_info.business_id
            }
            await this.axios
            .post(
            process.env.VUE_APP_SERVICE_ADMIN_BOX +
              "/api/v1/smartbox/get/template",
            payload,
            {
              headers: { Authorization: auth.code },
            }
            )
            .then((response) => {
              this.template_data = []
              response.data.data.forEach((items, index) => {
                let obj_template = {}
                obj_template["type"] = "mdi-note-text"
                obj_template["template_id"] = items.template_id
                obj_template["template_date_created"] = items.cre_dtm
                obj_template["template_creator"] = ""
                obj_template["create_name_template"] = items.template_name
                this.template_data.push(obj_template)
              })
  
              response.data.data.forEach(async (items, index)=>{
                this.template_data[index]["template_creator"] = await this.fn_getowner_template(items.user_id)
              })
              try{
                this.show_template_data =  !["", undefined, null, "null"].includes(this.search_template_name) ? this.fn_searching_by_name(this.search_template_name) : this.template_data
                this.isloading_page_skeleton = false
              } catch (err) {
                this.show_template_data = this.template_data
                this.search_template_name = ""
                this.isloading_page_skeleton = false
              }
              
              this.isloading_page_skeleton = false
            })
          },
          async fn_getowner_template(userid) {
            let auth = await gbfGenerate.generateToken();
            console.log("AUTH ", auth)
            let name_creator = ""
            await this.axios
            .post(
            process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/admin/get_data_by_user_id",
            {user_id: userid},{headers: { Authorization: auth.code },}
            )
            .then((response)=> {
              if(response.data.status === 'OK'){
                name_creator = response.data.result.t_named
              }
            })
  
            return name_creator
          },
          fn_close_dialog (type_close) {
            this.isOpen_template = false
            if(type_close === 'CLOSE_LOADING'){
                this.fn_get_template()
            }
          },
          fn_formatdatetime(_datetime) {
            if (
              _datetime === "" ||
              _datetime === "-" ||
              _datetime === undefined ||
              _datetime === null
            ) {
              return "-";
            } else {
              let dateyear =
                _datetime.split("")[0] +
                _datetime.split("")[1] +
                _datetime.split("")[2] +
                _datetime.split("")[3];
              let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
              let dateday = _datetime.split("")[6] + _datetime.split("")[7];
              let hour = _datetime.split("")[8] + _datetime.split("")[9];
              let minute = _datetime.split("")[10] + _datetime.split("")[11];
              let second = _datetime.split("")[12] + _datetime.split("")[13];
  
              return (
                dateday +
                "/" +
                datemonth +
                "/" +
                dateyear +
                " " +
                hour +
                ":" +
                minute 
                // +
                // ":" +
                // second
              );
            }
          },
          fn_clickpagination(event) {
            console.log("Event click pagination", event)
          },
          fn_clear_search () {
            this.size = 10
            this.search_template_name = ""
            this.show_template_data = this.template_data
          },
          fn_searching_by_name (name_template) {
            return this.template_data.filter((item) => {
              let findWord = name_template.toLowerCase()  
              if(item.create_name_template.toLowerCase().includes(findWord) || item.template_creator.toLowerCase().includes(findWord)){
                console.log("itemss ", item);
                return item
              }
            })
          }
  
      },
      mounted(){
       this.loaddata();
       this.title_en = this.dataAccountActive.business_info.account_title_eng;
       this.title_th = this.dataAccountActive.business_info.account_title_th;
       this.email = this.dataAccountActive.business_info.email;
       this.first_name_th = this.dataAccountActive.business_info.first_name_th;
       this.first_name_eng = this.dataAccountActive.business_info.first_name_eng;
       this.mobile = this.dataAccountActive.business_info.mobile;
       this.fn_get_template()
       
      },
      created () {
        this.backup_template_form_field = this.mockup_template
      }
      
  
  }
  </script>
  
  
  <style scoped>
  ::v-deep .v-text-field input {
    /* Your custom styles for the input element go here */
      line-height: inherit;
      }
  
  </style>